<template>
  <MainLayout>
    <v-card class="ma-5">
      <v-card-title>
        <v-icon>mdi-view-list</v-icon>
        WAGONS
      </v-card-title>
      <v-card-text>
        <GraffitiModal :graffiti-params="graffitiParams"
                       v-model="graffitiModal"/>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Graffities count
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Analyzed
              </th>
              <th class="text-left">
                Detected graffities
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="wagon of wagons">
              <WagonRow :wagon="wagon"
                        :trainUid="createTrainUid()"
                        @openModal="showPhotoModal"
                        :key="wagon.id"/>
            </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </MainLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainLayout from '../../components/Layouts/MainLayout.vue';
import GraffitiModal from '../../components/GraffitiModal.vue';

export default {
  name: 'WagonsPage',
  components: {
    WagonRow: () => import('../../components/WagonRow.vue'),
    MainLayout,
    GraffitiModal,
  },

  computed: {
    ...mapState('resources', ['wagons']),
  },

  data: () => ({
    graffitiModal: false,
    graffitiParams: {
      trainUID: null,
      wagonUid: null,
      imageType: null,
    },
  }),

  created() {
    this.getWagonsFromApi(
      { placeId: this.$route.params.placeId, trainId: this.$route.params.trainId },
    );
  },

  methods: {
    ...mapActions('resources', ['getWagonsFromApi']),
    createTrainUid() {
      return parseInt(this.$route.params.trainUid, 10);
    },
    showPhotoModal(data) {
      this.graffitiModal = true;
      this.graffitiParams = data;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
