<template>
  <v-dialog v-model="isVisible"
            overlay-color="white"
            overlay-opacity="0.6"
            max-width="940">
    <v-card>
      <v-card-title>
        {{ graffitiParams.imageType }}
      </v-card-title>
      <v-card-text>
        <img class="fit-img"
             :src="imageSrc"
             @error="alert('PP')"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GraffitiModal',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    graffitiParams: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    imageSrc: '',
  }),

  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    isVisible(oldValue) {
      if (oldValue) {
        const { trainUID, wagonId, imageType } = this.graffitiParams;
        this.imageSrc = `http://dev-graffiti.dotspot.at:12061/train/file/image/${trainUID}/${wagonId}/${imageType}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fit-img {
  width: 100%;
}

</style>
